import { GET_LOGGED_IN_USER_QUERY, LOGIN_QUERY } from '@/lib/queries/login.query';
import {
  LoggedInUserQuery,
  LoggedInUserQueryVariables,
  LoginQuery,
  LoginQueryVariables,
} from '@/lib/types/graphql';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  FormHelperText,
  IconButton,
  InputAdornment,
  LinearProgress,
  Typography,
} from '@mui/material';
import MuiLink from '@mui/material/Link';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import TTTextField from 'lib/components/form/tt-text-field';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useClient } from 'urql';

interface LoginFormValues {
  email: string;
  password: string;
  form?: never;
}

export function Login() {
  const client = useClient();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const login = async (
    values: FormikValues,
    { setSubmitting, setFieldError }: FormikHelpers<LoginFormValues>,
  ) => {
    setSubmitting(true);
    try {
      const user = await client
        .query<LoginQuery, LoginQueryVariables>(LOGIN_QUERY, {
          email: values.email,
          password: values.password,
        })
        .toPromise();
      if (!user.error) {
        router.push('/sites');
      } else {
        throw new Error('Invalid credentials');
      }
    } catch {
      setFieldError('form', 'Invalid login credentials');
    } finally {
      setSubmitting(false);
    }
  };

  let errorMessage: string;
  if (router.query.messageType === 'error' && router.query.message) {
    errorMessage = router.query.message as string;
  }
  useEffect(() => {
    (async () => {
      const userQuery = await client
        .query<LoggedInUserQuery, LoggedInUserQueryVariables>(GET_LOGGED_IN_USER_QUERY)
        .toPromise();
      if (userQuery.error || !userQuery.data?.loggedInUser) {
        setLoading(false);
      } else {
        router.push('/sites');
      }
    })();
  }, []);

  return (
    <>
      <Head>
        <title>Two Taps | Login</title>
      </Head>
      {loading && <LinearProgress variant="indeterminate" />}
      <Container maxWidth="md">
        {!loading && (
          <Box justifyContent="center">
            <Box marginTop={24} marginLeft="auto" marginRight="auto">
              <Card
                sx={{
                  maxWidth: 500,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <CardContent>
                  <Typography color="textPrimary" variant="h2">
                    {'Sign in'}
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle1">
                    {'Sign in on the internal platform'}
                  </Typography>
                  <Box mt={2}>
                    <Formik<LoginFormValues>
                      initialValues={{ email: '', password: '' }}
                      onSubmit={login}
                    >
                      {({ handleSubmit, errors, isSubmitting }) => (
                        <form onSubmit={handleSubmit}>
                          <FormHelperText
                            sx={{ visibility: errors.form ? 'visible' : 'hidden' }}
                            error={true}
                          >
                            {errors.form || ' '}
                          </FormHelperText>
                          <TTTextField name="email" label="Email" />
                          <TTTextField
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {!errorMessage && !!errors.form && (
                            <Box sx={{ mb: 2 }}>
                              <Alert severity="error">
                                The email or password is incorrect. If you&apos;ve forgotten your
                                password, click{' '}
                                <Link href="/password-reset" passHref>
                                  <MuiLink color="error">here</MuiLink>
                                </Link>{' '}
                                to reset it.
                              </Alert>
                            </Box>
                          )}
                          {errorMessage && (
                            <Box sx={{ mb: 2 }}>
                              <Alert severity="error">{errorMessage}</Alert>
                            </Box>
                          )}
                          <Box marginTop={2} marginBottom={2}>
                            <LoadingButton
                              loading={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Login
                            </LoadingButton>
                          </Box>
                          {/* <Divider />
                        <Box marginTop={2}>
                          <Button variant="contained" color="primary" href={publicRuntimeConfig.FRANCONNECT_LOGIN_URL}>
                            Login with Franconnect
                          </Button>
                        </Box> */}
                        </form>
                      )}
                    </Formik>
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Link href="/password-reset" passHref>
                <MuiLink>Reset password</MuiLink>
              </Link>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
}

export default Login;
